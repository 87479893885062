import BannerTravelRounded from 'components/Banner/BannerTravelRounded';
import ARTICLES_LIST_SHAPE from '../Images/Shapes/theme-two/articlesList-bg-shape.svg';
import FEATURED_SHAPE from '../Images/Shapes/theme-two/featured-bg-shape.svg';
import { darken, lighten, rgba } from '../Mixins.style';
import { Colors } from '../Variables.style';
import {
  faCaretLeft,
  faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons';

const theme = {
  global: {
    background: Colors.cultured,
    fontFamily: 'Lato, sans-serif'
  },
  articlePage: {
    background: 'transparent'
  },
  fontface: {
    primaryFont: 'Lato',
    primaryFontName: 'Lato',
    secondaryFont: 'Anton',
    secondaryFontName: 'Anton'
  },
  title: {
    wrapper: {
      alignItems: 'stretch',
      margin: {
        default: '0 0 20px 0',
        md: '0 0 10px 0'
      },
      padding: {
        default: 0,
        md: 0
      },
      borderRadius: {
        default: '0',
        md: '0'
      },
      background: 'transparent',
      isBackground: true,
      borderBottom: `1px solid ${Colors.crayola}`
    },
    text: {
      padding: {
        default: '16px 0',
        md: '10px 15px'
      },
      color: Colors.msugreen,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 300
    },
    link: {
      margin: {
        default: '0 0 0 auto',
        md: '0 0 0 auto'
      },
      padding: {
        default: '20px',
        md: '10px 15px'
      },
      borderRadius: {
        default: '0 8px 8px 0',
        md: '0 4px 4px 0',
        sm: '0 4px 4px 0'
      },
      background: 'transparent',
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50),
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
      // triangleShape: {
      //   top: 0,
      //   right: 'initial',
      //   left: '-34px',
      //   bottom: 0,
      //   width: '35px',
      //   height: '100%',
      //   background: Colors.pictonBlue,
      //   clipPath: 'polygon(100% 0, 0 100%, 100% 100%)'
      // }
    },
    arrow: {
      faIcon: faAngleDoubleRight,
      margin: {
        default: '0 0 -2px 5px',
        md: '0 0 -2px 5px'
      },
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  button: {
    padding: {
      default: '15px 25px',
      md: '5px 15px'
    }, //Czytaj wiecej
    background: Colors.tango,
    backgroundOnHover: darken(Colors.tango, 50),
    borderRadius: {
      default: '0'
    },
    color: Colors.white,
    colorOnHover: darken(Colors.white, 50),
    fontFamily: 'Lato, sans-serif',
    fontSize: {
      default: 1.6,
      lg: 1.6,
      md: 1.4,
      sm: 1.4
    },
    lineHeight: 1,
    fontWeight: 700
  },
  buttonIcon: {
    position: 'right-center',
    minWidth: 'auto',
    minHeight: 'auto',
    faIcon: faAngleDoubleRight,
    margin: {
      default: '0 5px 0 5px',
      md: '0 5px 0 5px'
    },
    borderRadius: {
      default: '0',
      md: 0
    },
    background: 'transparent',
    color: Colors.white
  },
  loadingScreen: {
    wrapper: {
      primaryBackground: Colors.white,
      secondaryBackground: Colors.white,
      color: Colors.msugreen
    },
    text: {
      fontSize: {
        default: 2.2,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  errorScreen: {
    wrapper: {
      primaryBackground: Colors.white,
      secondaryBackground: Colors.white,
      color: Colors.msugreen
    },
    text: {
      fontSize: {
        default: 2.2,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  loadingData: {
    wrapper: {
      primaryBackground: 'transparent',
      secondaryBackground: 'transparent',
      color: Colors.msugreen
    },
    text: {
      fontSize: {
        default: 2.2,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  toolbar: {
    wrapper: {
      color: Colors.msugreen
    },
    item: {
      left: {
        padding: {
          default: '2px',
          md: '2px'
        },
        background: Colors.darkCyan
      },
      right: {
        padding: {
          default: '2px',
          md: '2px'
        },
        background: Colors.darkCyan
      }
    }
  },
  toolbarSocial: {
    item: {
      color: Colors.white,
      colorOnHover: 'inherit',
      background: 'transparent',
      backgroundOnHover: Colors.tango,
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  toolbarSettings: {
    item: {
      color: Colors.msugreen,
      colorOnHover: 'inherit',
      background: 'transparent',
      backgroundOnHover: Colors.tango,
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  header: {
    wrapper: {
      background: Colors.white,
      boxShadow: `0 3px 6px 0 ${rgba(Colors.black, 0.16)}`,
      padding: {
        default: '15px 0 0',
        lg: '10px 0 0',
        md: '5px 0 0',
        sm: '2px 0 0'
      },
      zIndex: '2'
    },
    box: {
      gridGap: 0
    },
    toggleButton: {
      color: Colors.msugreen,
      colorOnHover: Colors.white,
      backgroundOnHover: Colors.tango,
      colorOnToggle: Colors.white,
      backgroundOnToggle: Colors.tango
    },
    logo: {
      image: {
        width: 1395,
        height: 690,
        widthTablet: 969,
        heightTablet: 480,
        widthMobile: 767,
        heightMobile: 480,
        crop: 0
      }
    }
  },
  blackHole: {
    wrapper: {
      color: Colors.bigStone
    },
    title: {
      fontSize: {
        default: 2.4,
        lg: 2.4,
        md: 2.2,
        sm: 2.2
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    text: {
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  banner: {
    box: {
      boxShadow: `none`
    },
    item: {
      height: {
        default: '680px',
        lg: '480px'
      }
    },
    image: {
      width: 1395,
      // height: 690,
      height: 830,
      widthTablet: 969,
      heightTablet: 480,
      widthMobile: 767,
      heightMobile: 480,
      background: Colors.msugreen
    },
    contentInner: {
      gridRows: {
        default: '1fr 90px',
        lg: '1fr'
      }
    },
    details: {
      justifyContent: 'center',
      padding: {
        default: '25px 0 55px',
        md: '15px 0'
      }
    },
    detailsInner: {
      margin: {
        default: 0,
        md: 0
      },
      padding: {
        default: 0,
        md: 0
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent'
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      padding: {
        default: '20px',
        md: '10px 15px'
      },
      borderRadius: {
        default: '8px',
        md: '4px'
      },
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 6.0,
        lg: 4.8,
        md: 3.6,
        sm: 2.8
      },
      lineHeight: 1.2,
      fontWeight: 300
    },
    button: {
      faIcon: faAngleDoubleRight,
      margin: '0 20px'
    },
    buttonIcon: {
      margin: {
        default: '0 5px -2px'
      },
      borderRadius: {
        default: '0'
      }
    },
    navigation: {
      top: '50px',
      bottom: '85px',
      paddingBottom: '50px'
    },
    travel: {
      margin: {
        default: '-120px 0 0',
        lg: 0
      }
    }
  },
  bannerTravel: {
    holder: {
      shape: undefined
    },
    wrapper: {
      margin: {
        default: 0,
        lg: 0
      },
      borderRadius: { default: '4px', lg: 0 },
      background: 'none',
      boxShadow: {
        default: `none`,
        lg: `none`
      }
    },
    box: {
      padding: {
        default: 0,
        lg: 0
      },
      gap: '20px'
    },
    itemHolder: {
      padding: '0',
      borderRight: `1px solid ${Colors.mercury}`,
      flexGrow: '0',
      borderRadius: '50%',
      width: '220px',
      height: '220px',
      backgroundColor: Colors.darkCyan,
      theme5: {},
      justifyContent: {
        default: 'center',
        lg: 'center'
      }
    },
    item: {
      padding: {
        default: '0'
      },
      border: rgba(Colors.black, 0.09),
      roundedButton: {
        background: Colors.darkCyan,
        backgroundHover: darken(Colors.darkCyan, 50)
      }
    },
    image: {
      width: 50,
      height: 38,
      borderRadius: {
        default: '100%',
        md: '100%'
      }
    },
    title: {
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      bannerTitleSpacing: '0',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400,
      roundedButton: {}
    },
    titleContent: {
      roundedButton: {}
    },
    arrow: {
      outside: true,
      color: Colors.white,
      colorOnHover: darken(Colors.white, 50),
      faIcon: faAngleDoubleRight
    },
    imagebox: {
      display: 'none',
      width: '0',
      height: '0'
    }
  },
  bannerTravelRounded: {
    holder: {
      shape: undefined
    },
    wrapper: {
      margin: {
        default: 0,
        lg: 0
      },
      borderRadius: { default: '4px', lg: 0 },
      background: 'none',
      boxShadow: {
        default: `none`,
        lg: `none`
      }
    },
    box: {
      padding: {
        default: 0,
        lg: 0
      },
      gap: '20px'
    },
    itemHolder: {
      padding: '0',
      borderRight: `1px solid ${Colors.mercury}`,
      flexGrow: '0',
      borderRadius: '50%',
      width: '220px',
      height: '220px',
      backgroundColor: Colors.darkCyan,
      theme5: {},
      justifyContent: {
        default: 'center',
        lg: 'center'
      }
    },
    item: {
      padding: {
        default: '0'
      },
      border: rgba(Colors.black, 0.09),
      roundedButton: {
        background: Colors.darkCyan,
        backgroundHover: darken(Colors.darkCyan, 50)
      }
    },
    image: {
      width: 50,
      height: 38,
      borderRadius: {
        default: '100%',
        md: '100%'
      }
    },
    title: {
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      bannerTitleSpacing: '0',
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 2.0,
        sm: 2.0
      },
      lineHeight: 1.2,
      fontWeight: 400,
      roundedButton: {}
    },
    titleContent: {
      roundedButton: {}
    },
    arrow: {
      outside: true,
      color: Colors.white,
      colorOnHover: darken(Colors.white, 50),
      faIcon: faAngleDoubleRight
    },
    imagebox: {
      display: 'none',
      width: '0',
      height: '0'
    }
  },
  bannerNavigation: {
    wrapper: {
      top: '0',
      transform: 'none',
      height: '100%'
    },
    panel: {
      background: rgba(Colors.black, 0.6),
      borderRadius: {
        default: '0'
      }
    },
    panelInner: {
      margin: '15px 0',
      padding: '8px 20px',
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    },
    navigationButton: {
      position: 'absolute',
      width: '100%',
      height: '22px',
      isBackground: true,
      background: Colors.tango,
      backgroundOnHover: darken(Colors.tango, 50),
      color: Colors.white,
      colorOnHover: Colors.white,
      margin: '2px 0',
      prev: {
        top: '-24px',
        left: '50%',
        right: 'initial',
        transform: 'translate(-50%, 0)',
        borderRadius: '4px 4px 0 0'
      },
      next: {
        bottom: '-24px',
        left: '50%',
        right: 'initial',
        transform: 'translate(-50%, 0)',
        borderRadius: '0 0 4px 4px'
      }
    },
    box: {
      gridGap: '15px',
      padding: '5px 0 0 0'
    },
    item: {
      minHeight: '129px',
      padding: '0 15px',
      margin: '10px 0',
      background: 'transparent',
      backgroundOnSelected: 'transparent',
      border: 0,
      borderOnSelected: 0,
      borderOnSelectedColor: 'transparent',
      borderAfterOnSelected: 0,
      borderAfterOnSelectedColor: 'transparent'
    },
    itemInner: {
      gridColumns: '142px 1fr',
      background: Colors.white,
      margin: '5px 0 5px 0'
    },
    imagebox: {
      width: '142px',
      minHeight: '129px',
      height: '100%',
      margin: 0
      // triangleShape: {
      //   top: 0,
      //   right: 0,
      //   left: 'initial',
      //   bottom: 0,
      //   width: '35px',
      //   height: '100%',
      //   background: Colors.pictonBlue,
      //   clipPath: 'polygon(75% 0, 100% 0, 100% 100%, 0% 100%)'
      // }
    },
    image: {
      width: 200,
      height: 150,

      borderRadius: '4px 0 0 4px'
    },
    featured: {
      top: '0',
      right: 'initial',
      left: 0,
      bottom: 'initial',
      transform: 'translate(0, 0)',
      justifyContent: 'center',
      shape: '',
      isText: true,
      minWidth: 'auto',
      minHeight: '23px',
      padding: '4px 5px 4px 20px',
      borderRadius: '0',
      background: Colors.darkCyan,
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: 1.0,
      lineHeight: 1.2,
      fontWeight: 400,
      boxShadow: 'none',
      minWidth: '100%'
    },
    star: {
      position: 'absolute',
      top: '50%',
      left: '0',
      transform: 'translate(-25%, -50%)',
      width: '30px',
      height: '30px',
      margin: 0,
      borderRadius: '100%',
      background: Colors.tango,
      color: Colors.white,
      fontSize: 1.6
    },
    details: {
      padding: '15px'
      // triangleShape: {
      //   top: 0,
      //   right: 'initial',
      //   left: '-35px',
      //   bottom: 0,
      //   width: '35px',
      //   height: '100%',
      //   background: Colors.white,
      //   clipPath: 'polygon(100% 0, 0 100%, 100% 100%)'
      // }
    },
    title: {
      margin: '0 0 15px 0',
      padding: 0,
      color: Colors.msugreen,
      fontFamily: 'Lato, sans-serif',
      fontSize: 1.8,
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      isText: true,
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50),
      fontFamily: 'Lato, sans-serif',
      fontSize: 1.2,
      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      position: 'right-center',
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: 0,
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.tango,
      faIcon: faAngleDoubleRight,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  headline: {
    category: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.tango,
      fontSize: {
        default: 3.6,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      lineHeight: 1.2,
      fontWeight: 700
    },
    title: {
      margin: {
        default: '0 0 55px 0',
        md: '0 0 25px 0'
      },
      color: Colors.msugreen,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 4.8,
        lg: 3.6,
        md: 2.8,
        sm: 2.2
      },
      lineHeight: 1.2,
      fontWeight: 300
    },
    subtitle: {
      margin: {
        default: '0 0 55px 0',
        md: '0 0 25px 0'
      },
      color: Colors.bigStone,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 3.6,
        lg: 2.8,
        md: 2.2,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    imagebox: {
      margin: {
        default: '0 0 55px 0',
        md: '0 0 25px 0'
      }
    },
    image: {
      width: 1395,
      height: 480,
      widthTablet: 922,
      heightTablet: 317,
      widthMobile: 720,
      heightMobile: 180,

      borderRadius: {
        default: 0,
        md: 0
      }
    }
  },
  articleGallery: {
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.msugreen,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    caption: {
      fontFamily: 'Anton, sans-serif'
    },
    image: {
      width: 230,
      height: 173,
      widthTablet: 307,
      heightTablet: 307,
      widthMobile: 360,
      heightMobile: 360
    }
  },
  widgets: {
    wrapper: {
      gridGap: {
        default: '15px',
        md: '30px'
      }
    }
  },
  widgetDisabledFacilities: {
    item: {
      color: Colors.msugreen,
      colorOnHover: Colors.msugreen,
      colorOnActive: Colors.msugreen,
      background: Colors.tango,
      backgroundOnHover: darken(Colors.tango, 50),
      backgroundOnActive: darken(Colors.tango, 50),
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  widgetTitle: {
    title: {
      borderBottom: `1px solid ${Colors.crayola}`,
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      padding: {
        default: '20px 0',
        md: '10px 15px'
      },
      borderRadius: {
        default: '0',
        md: '0'
      },
      background: 'none',
      isBackground: true,
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 300
    },
    spacer: {
      isSpacer: false,
      background: rgba(Colors.msugreen, 0.1)
    }
  },
  widgetSchoolMenu: {
    backgroundColor: Colors.casper,
    borderRadius: '4px',
    title: {
      padding: {
        default: '26px 0 26px 0'
      },
      backgroundColor: Colors.ziggurat,
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    image: {
      margin: {
        default: '0 4px 0 0'
      }
    },
    date: {
      margin: {
        default: '0 0 16px 0'
      },
      padding: {
        default: '0 0 24px 0'
      },
      backgroundColor: Colors.ziggurat,
      borderRadius: '50%',
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    mealsBox: {
      padding: {
        default: '0 20px 18px'
      }
    },
    meal: {
      margin: {
        default: '0 0 24px 0'
      },
      padding: {
        default: '0 0 24px 0'
      },
      border: `1px dashed ${Colors.white}`
    },
    innerTitle: {
      margin: {
        default: '0 0 8px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    text: {
      color: Colors.white,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    decoration: {
      color: Colors.orange,
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.8
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    footer: {
      backgroundColor: Colors.blueberry,
      padding: {
        default: '20px 64px 20px 20px'
      }
    },
    footerText: {
      margin: { default: '0' },
      color: Colors.white,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    arrow: {
      backgroundColor: Colors.white,
      color: Colors.newYork,
      filterShadow: 'drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2))',
      fontSize: 2.0,
      offset: '-33px',
      size: 66
    }
  },
  widgetLessonPlan: {
    backgroundColor: Colors.lightGreen,
    borderRadius: '4px',
    title: {
      padding: {
        default: '26px 0 26px 0'
      },
      backgroundColor: Colors.jetStream,
      color: Colors.blueberry,
      fontFamily: 'Segoe UI, sans-serif',
      fontSize: {
        default: 3.2,
        lg: 3.2,
        md: 2.8,
        sm: 2.4
      },
      fontWeight: 400,
      lineHeight: 1.2
    },
    image: {
      margin: {
        default: '0 4px 0 0'
      }
    },
    selectContainer: {
      padding: {
        default: '0 24px 40px'
      },
      backgroundColor: Colors.jetStream
    },
    selectText: {
      margin: {
        default: '0 0 4px 0'
      },
      color: Colors.blueberry,
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    select: {
      padding: {
        default: '9px 17px 9px 12px'
      },
      border: `1px solid ${Colors.acapulco}`,
      borderRadius: '4px',
      color: Colors.blueberry,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    date: {
      margin: {
        default: '0 0 16px 0'
      },
      padding: {
        default: '5px 0 24px 0'
      },
      backgroundColor: Colors.jetStream,
      borderRadius: '50%',
      color: Colors.blueberry,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      fontWeight: 900,
      lineHeight: 1.2
    },
    table: {
      padding: {
        default: '0 20px 34px'
      }
    },
    content: {
      color: Colors.blueberry,
      secondColor: Colors.white,
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      fontWeight: 700,
      lineHeight: 1.2,
      row: {
        padding: {
          default: '16px 4px 16px 4px'
        },
        border: `1px dashed ${Colors.white}`
      }
    },
    arrow: {
      backgroundColor: Colors.white,
      color: Colors.newYork,
      filterShadow: 'drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2))',
      fontSize: 2.0,
      offset: '-33px',
      size: 66
    }
  },
  widgetSpacer: {
    spacer: {
      background: rgba(Colors.msugreen, 0.1)
    }
  },
  widgetList: {
    title: {
      margin: {
        default: '0 10px 15px',
        md: '0 0 5px 0'
      },
      padding: {
        default: '20px 0',
        md: '10px 15px'
      },
      borderRadius: {
        default: '0',
        md: '0'
      },
      background: 'transparent',
      isBackground: true,
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      borderColor: 400,
      borderColor: `1px solid ${Colors.crayola}`
    },
    box: {
      gridGap: {
        default: 0,
        md: 0
      }
    }
  },
  widgetItem: {
    item: {
      minHeight: {
        default: 'initial',
        md: 'initial'
      },
      margin: {
        default: 0,
        md: 0
      },
      padding: {
        default: '15px 20px',
        md: '10px 15px'
      },
      gridGap: {
        default: '30px',
        md: '20px'
      },
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      border: `1px solid ${rgba(Colors.msugreen, 0.1)}`,
      borderFirstTop: `none`,
      borderLastBottom: `1px solid ${rgba(Colors.msugreen, 0.1)}`,
      boxShadow: 'none',
      gridTemplateColumns: '85px 1fr'
    },
    imagebox: {
      width: '85px',
      height: '85px',
      margin: {
        default: 0,
        md: 0
      }
    },
    image: {
      width: 85,
      height: 85,
      borderRadius: {
        default: '0',
        md: '0'
      }
    },
    text: {
      margin: {
        default: '0',
        md: '0'
      },
      color: Colors.msugreen,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.tango,
      faIcon: faAngleDoubleRight,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  widgetContactForm: {
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      padding: {
        default: '20px',
        md: '10px 15px'
      },
      borderRadius: {
        default: '8px',
        md: '4px'
      },
      background: Colors.msugreen,
      isBackground: true,
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    spacer: {
      isSpacer: false,
      background: rgba(Colors.msugreen, 0.1)
    },
    box: {
      gridGap: {
        default: 0,
        md: 0
      },
      padding: {
        default: '15px 0',
        md: '10px 0'
      }
    },
    item: {
      margin: {
        default: '0 0 25px 0',
        md: '0 0 15px 0'
      }
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      background: Colors.msugreen,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    textarea: {
      padding: {
        default: '10px',
        md: '5px'
      },
      background: Colors.white,
      border: '#ebedf0',
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    checkboxLabel: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      background: Colors.msugreen,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    checkbox: {
      border: '#ebedf0',
      background: Colors.white,
      color: '#ebedf0'
    },
    button: {
      padding: {
        default: '0 10px',
        md: '0 5px'
      },
      background: Colors.tango,
      backgroundOnHover: darken(Colors.tango, 50),
      color: Colors.white,
      colorOnHover: darken(Colors.white, 50),
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  widgetImage: {
    image: {}
  },
  widgetAdvertisement: {
    image: {}
  },
  widgetBanner: {
    image: {},
    title: {
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  widgetIcon: {
    item: {
      color: Colors.msugreen
    },
    text: {
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700
    }
  },
  widgetLuckyNumber: {
    title: {
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 2.0
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    text: {
      color: Colors.white,
      fontSize: {
        default: 7.2,
        lg: 6.4,
        md: 5.2,
        sm: 4.8
      },
      lineHeight: 1.2,
      fontWeight: 700,
      fontStyle: 'italic'
    }
  },
  widgetPartners: {
    title: {
      margin: {
        default: '0 0 40px 0',
        md: '0 0 20px 0'
      },
      color: Colors.black,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 3.0,
        lg: 3.0,
        md: 2.0,
        sm: 2.0
      },
      fontWeight: 200,
      lineHeight: 1.2
    }
  },
  widgetCalendar: {
    backgroundColor: Colors.sprout,
    container: {
      padding: {
        default: '36px 26px 9px 26px'
      }
    },
    box: {
      margin: {
        default: '0 0 22px 0'
      }
    },
    calendarTop: {
      margin: {
        default: '0 0 20px 0'
      }
    },
    arrowButton: {
      fontSize: {
        default: 2.7,
        sm: 2.0
      }
    },
    monthYear: {
      color: Colors.black,
      fontSize: {
        default: 2.3,
        sm: 1.8
      },
      fontWeight: 200,
      fontFamily: 'Metropolis, sans-serif'
    },
    dayNamesWrapper: {
      margin: {
        default: '0 0 12px 0'
      }
    },
    dayName: {
      color: Colors.black,
      fontSize: {
        default: 1.4,
        xs: 1.2
      },
      fontWeight: 200,
      fontFamily: 'Metropolis, sans-serif'
    },
    table: {
      rowGap: '7px',
      columnGap: '1px'
    },
    day: {
      padding: {
        default: '7px 0 7px 0',
        sm: '5px 0 5px 0'
      },
      backgroundColor: {
        default: Colors.white,
        weekend: Colors.mercury2,
        clickable: Colors.camouflageGreen,
        hover: lighten(Colors.camouflageGreen, 10),
        selected: Colors.shiraz
      },
      color: Colors.black,
      borderColor: Colors.dove,
      fontSize: {
        default: 2.3,
        sm: 1.8
      },
      fontWeight: 200,
      fontFamily: 'Metropolis, sans-serif',
      width: {
        default: '36px',
        sm: '25px'
      }
    },
    event: {
      color: Colors.white,
      margin: {
        default: '0 0 20px 0'
      },
      padding: {
        default: '0 50px 0 0',
        sm: '0 30px 0 0'
      },
      after: {
        padding: '14px 17px 14px 17px',
        paddingSm: '7px 9px 7px 9px',
        backgroundColor: Colors.doveGray,
        backgroundColorOnHover: darken(Colors.doveGray, 50),
        color: Colors.white,
        content: 'więcej',
        fontFamily: 'Metropolis, sans-serif',
        fontSize: 1.4,
        fontSizeSm: 1.2,
        fontWeight: 700,
        lineHeight: 1.2
      }
    },
    title: {
      margin: {
        default: '0 0 5px 0'
      },
      color: Colors.white,
      fontSize: {
        default: 1.1
      },
      fontWeight: 700,
      fontFamily: 'Metropolis, sans-serif'
    },
    text: {
      color: Colors.white,
      fontSize: {
        default: 1.1
      },
      fontWeight: 400,
      fontFamily: 'Metropolis, sans-serif'
    },
    link: {
      padding: {
        default: '14px 12px 14px 12px',
        sm: '7px 6px 7px 6px'
      },
      backgroundColor: {
        default: Colors.doveGray,
        hover: darken(Colors.doveGray, 50)
      },
      color: Colors.white,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: 1.4,
      fontWeight: 700
    }
  },
  articlesList: {
    wrapper: {
      margin: {
        default: '0 0 15px 0',
        lg: '0 0 15px 0',
        md: '0 0 5px 0',
        sm: '0 0 5px 0'
      }
    },
    box: {
      gridGap: {
        default: '20px',
        md: '10px 0'
      },
      gridColumns: {
        default: '1fr 1fr',
        md: '1fr'
      }
    },
    itemHolder: {
      shape: undefined,
      left: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      right: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      }
    },
    item: {
      gridColumns: {
        default: '1fr',
        lg: '1fr',
        md: '1fr 1fr',
        sm: '1fr'
      },
      gridRows: {
        default: '385px 1fr'
      },
      gridGap: 0,
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      background: Colors.alabaster,
      borderRadius: {
        default: '8px',
        md: '4px'
      },
      border: {
        left: `1px solid ${Colors.galleryTwo}`,
        right: `1px solid ${Colors.galleryTwo}`,
        bottom: `1px solid ${Colors.galleryTwo}`,
        top: `1px solid ${Colors.galleryTwo}`,
        lastOfType: {
          bottom: `1px solid ${Colors.galleryTwo}`
        }
      },
      boxShadow: 'none',
      height: '100%'
    },
    imagebox: {
      maxWidth: '100%',
      height: '100%',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      }
      // triangleShape: {
      //   display: {
      //     default: 'block',
      //     lg: 'none',
      //     md: 'block',
      //     sm: 'none'
      //   },
      //   top: 0,
      //   right: 0,
      //   left: 'initial',
      //   bottom: 0,
      //   width: '35px',
      //   height: '100%',
      //   background: Colors.pictonBlue,
      //   clipPath: 'polygon(75% 0, 100% 0, 100% 100%, 0% 100%)'
      // }
    },
    image: {
      width: 452,
      height: 300,
      widthTablet: 456,
      heightTablet: 342,
      widthMobile: 720,
      heightMobile: 540,
      borderRadius: {
        default: '0',
        md: '0'
      },
      heightStyle: '100%'
    },
    featured: {
      top: '0',
      bottom: '0',
      right: 'initial',
      left: 0,
      bottom: 'unset',
      transform: 'translate(0, 0)',
      justifyContent: 'center',
      shape: '',
      isText: true,
      display: 'block',
      width: '100%',
      minWidth: {
        default: 'auto',
        lg: 'auto',
        md: 'auto',
        sm: 'auto'
      },
      minHeight: {
        default: '23px',
        lg: '23px',
        md: '23px',
        sm: '23px'
      },
      padding: '8px 5px 8px 20px',
      fontSize: 1.4,
      lineHeight: 1.6,
      borderRadius: {
        default: '0',
        md: '0'
      },
      background: Colors.darkCyan,
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400,
      boxShadow: `0 3px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    star: {
      position: 'absolute',
      top: '50%',
      bottom: 0,
      // left: 'auto',
      right: '0',
      transform: 'translate(-50%, -50%)',
      width: '35px',
      height: '35px',
      margin: 0,
      borderRadius: '100%',
      background: Colors.tango,
      color: Colors.white,
      fontSize: {
        default: 1.5,
        lg: 1.5,
        md: 1.5,
        sm: 1.5
      }
    },
    details: {
      height: '100%',
      alignItems: 'flex-start',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: '25px',
        lg: '25px',
        md: '15px',
        sm: '15px'
      }
      // triangleShape: {
      //   display: {
      //     default: 'block',
      //     lg: 'none',
      //     md: 'block',
      //     sm: 'none'
      //   },
      //   top: 0,
      //   right: 'initial',
      //   left: '-35px',
      //   bottom: 0,
      //   width: '35px',
      //   height: '100%',
      //   background: Colors.alabaster,
      //   clipPath: 'polygon(100% 0, 0 100%, 100% 100%)'
      // }
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.msugreen,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 600
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.bigStone,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      isText: true,
      color: Colors.white,
      colorOnHoverLink: darken(Colors.white),
      backgroundColor: Colors.tango,
      backgroundColorOnHover: darken(Colors.tango, 50),
      padding: '8px 20px',
      fontFamily: 'Lato, sans-serif',
      borderRadius: '0',
      fontWeight: 500,
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.2,
        sm: 1.2
      }
    },
    arrow: {
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: {
        default: 0,
        md: 0
      },
      faIcon: 'none',
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.white,
      colorOnHover: darken(Colors.white, 50)
    }
  },
  articlesGrid: {
    wrapper: {
      margin: {
        default: '0 0 15px 0',
        lg: '0 0 15px 0',
        md: '0 0 5px 0',
        sm: '0 0 5px 0'
      }
    },
    box: {
      border: 'transparent',
      padding: {
        default: '25px 0 25px',
        md: '15px 0 15px'
      },
      gridTemplateColumns: {
        default: '1fr 1fr',
        lg: '1fr 1fr',
        md: '1fr 1fr',
        sm: '1fr'
      },
      gridGap: {
        default: '20px',
        lg: '20px',
        md: '20px',
        sm: '20px'
      }
    },
    itemHolder: {
      shape: undefined,
      left: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      main: {
        margin: {
          default: '0',
          lg: '0',
          md: '0',
          sm: '0'
        },
        gridArea: {
          default: '1 / 1 / 1 / 1',
          lg: '1 / 1 / 1 / 1',
          md: '1 / 1 / 1 / 1',
          sm: '1 / 1 / 1 / 1'
        },
        shape: undefined,
        left: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        }
      }
    },
    item: {
      gridColumns: {
        default: '1fr',
        lg: '1fr',
        md: '1fr',
        sm: '1fr'
      },
      gridRows: {
        default: '385titlepx 1fr',
        lg: '300px 1fr'
      },
      height: '100%',
      gridGap: 0,
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      background: 'none',
      borderRadius: {
        default: '0',
        md: '0'
      },
      border: {
        left: `none`,
        right: `none`,
        bottom: `none`,
        top: `none`,
        lastOfType: {
          bottom: `1px solid ${Colors.galleryTwo}`
        }
      },
      main: {
        gridColumns: {
          default: '1fr',
          lg: '1fr',
          md: '1fr',
          sm: '1fr'
        },
        gridRows: {
          default: '385px 1fr',
          lg: '300px 1fr'
        },
        height: '100%',
        gridGap: 0,
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        },
        padding: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        },
        background: 'none',
        borderRadius: {
          default: '8px',
          md: '4px'
        },
        border: {
          left: `1px solid ${Colors.galleryTwo}`,
          right: `1px solid ${Colors.galleryTwo}`,
          bottom: `1px solid ${Colors.galleryTwo}`,
          top: `1px solid ${Colors.galleryTwo}`,
          lastOfType: {
            bottom: `1px solid ${Colors.galleryTwo}`
          }
        }
      },
      boxShadow: 'none'
    },
    imagebox: {
      maxWidth: '100%',
      height: '100%',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      // triangleShape: {
      //   display: {
      //     default: 'block',
      //     lg: 'none',
      //     md: 'block',
      //     sm: 'none'
      //   },
      //   top: 0,
      //   right: 0,
      //   left: 'initial',
      //   bottom: 0,
      //   width: '35px',
      //   height: '100%',
      //   background: Colors.pictonBlue,
      //   clipPath: 'polygon(75% 0, 100% 0, 100% 100%, 0% 100%)'
      // },
      main: {
        maxWidth: '100%',
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        }
      }
    },
    image: {
      width: 570,
      height: 428,
      widthTablet: 456,
      heightTablet: 342,
      widthMobile: 720,
      heightMobile: 540,
      borderRadius: {
        default: '0',
        md: '0'
      },
      main: {
        width: 515,
        height: 386,
        widthTablet: 451,
        heightTablet: 338,
        widthMobile: 720,
        heightMobile: 540,

        borderRadius: {
          default: 0,
          md: 0
        }
      }
    },
    featured: {
      top: {
        default: '-5px',
        lg: '-5px',
        md: '-5px',
        sm: '-5px'
      },
      right: 'initial',
      left: '0',
      bottom: {
        default: 'unset',
        lg: 'unset',
        md: 'unset',
        sm: 'unset'
      },
      transform: 'translate(0, 0)',
      justifyContent: 'center',
      alignItems: 'flex-start',
      shape: '',
      isText: true,
      display: 'block',

      minWidth: {
        default: 'auto',
        lg: 'auto',
        md: 'auto',
        sm: 'auto'
      },
      minHeight: {
        default: 'auto',
        lg: 'auto',
        md: 'auto',
        sm: 'auto'
      },
      width: '100%',
      padding: '8px 8px 8px 25px',
      fontSize: 1.4,
      lineHeight: 1.6,
      borderRadius: {
        default: '0',
        md: '0'
      },
      main: {
        position: undefined,
        isText: true,
        padding: '6px 9px 6px 27px',
        minWidth: {
          default: 'auto',
          lg: 'auto',
          md: 'auto',
          sm: 'auto'
        },
        minHeight: {
          default: 'auto',
          lg: 'auto',
          md: 'auto',
          sm: 'auto'
        },
        borderRadius: {
          default: '6px',
          md: '4px'
        },
        left: '0',
        top: {
          default: '0',
          lg: '0',
          md: '0',
          sm: '0'
        },
        bottom: {
          default: 'unset',
          lg: 'unset',
          md: 'unset',
          sm: 'unset'
        },
        color: Colors.white,
        background: Colors.darkCyan,
        fontSize: {
          default: 1.5,
          lg: 1.5,
          md: 1.4,
          sm: 1.4
        },
        lineHeight: 1.2,
        fontWeight: 700
      },
      color: Colors.white,
      background: Colors.darkCyan,
      fontFamily: 'Lato, sans-serif',
      lineHeight: 1.2,
      fontWeight: 700,
      fontSize: {
        default: 1.5,
        lg: 1.3,
        md: 1.2,
        sm: 1.2
      }
      // boxShadow: `2px 1px 6px 1px ${rgba(Colors.black, 0.16)}`
    },
    star: {
      position: 'absolute',
      top: '50%',
      // left: '0',
      right: '0',
      transform: 'translate(-50%, -50%)',
      width: '35px',
      height: '35px',
      margin: 0,
      borderRadius: '100%',
      background: Colors.tango,
      color: Colors.white,
      faIcon: null,
      fontSize: {
        default: 1.5,
        lg: 1.5,
        md: 1.5,
        sm: 1.5
      },
      main: {
        width: '35px',
        height: '35px',
        color: Colors.white,
        faIcon: faAngleDoubleRight,
        fontSize: {
          default: 1.5,
          lg: 1.5,
          md: 1.5,
          sm: 1.5
        }
      }
    },
    details: {
      height: '100%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: '25px',
        lg: '25px',
        md: '15px',
        sm: '15px'
      },
      // triangleShape: {
      //   display: {
      //     default: 'block',
      //     lg: 'none',
      //     md: 'block',
      //     sm: 'none'
      //   },
      //   top: 0,
      //   right: 'initial',
      //   left: '-35px',
      //   bottom: 0,
      //   width: '35px',
      //   height: '100%',
      //   background: Colors.alabaster,
      //   clipPath: 'polygon(100% 0, 0 100%, 100% 100%)'
      // },
      main: {
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        },
        padding: {
          default: '25px',
          lg: '0 15px 15px',
          md: '15px',
          sm: '0 15px 15px'
        }
      }
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.msugreen,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.0,
        lg: 2.0,
        md: 1.8,
        sm: 1.8
      },
      main: {
        fontSize: {
          default: 1.8,
          lg: 1.8,
          md: 1.6,
          sm: 1.6
        }
      },
      lineHeight: 1.2,
      wordBreak: 'break-word',
      fontWeight: 600
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.msugreen,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400,
      show: true
    },
    link: {
      //link czytaj
      isText: true,
      color: Colors.white,
      colorOnHover: darken(Colors.white, 50),
      backgroundColor: Colors.tango,
      backgroundColorOnHover: darken(Colors.tango, 50),
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.2,
        sm: 1.2
      },
      lineHeight: 1.2,
      fontWeight: 500,
      padding: '8px 20px',
      main: {
        isText: true,
        color: Colors.msugreen,
        // colorOnHover: Colors.msugreen,
        backgroundColor: Colors.tango,
        backgroundColorOnHover: darken(Colors.tango, 50),
        padding: '8px 20px',
        borderRadius: '0',
        fontSize: {
          default: 1.3,
          lg: 1.3,
          md: 1.2,
          sm: 1.2
        }
      }
    },
    arrow: {
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: 'transparent',
      faIcon: faAngleDoubleRight,
      colorOnHover: darken(Colors.tango, 50),
      main: {
        color: Colors.white,
        faIcon: faAngleDoubleRight
      }
    }
  },
  pagination: {
    current: {
      borderRadius: { default: '0', lg: '0' },
      minWidth: {
        default: '40px',
        md: '36px'
      },
      width: {
        default: 'auto',
        md: 'auto'
      },
      minHeight: {
        default: '40px',
        md: '36px'
      },
      background: Colors.tango,
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.4,
        md: 2.0,
        sm: 2.0
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      borderRadius: { default: '0', lg: '0' },
      minWidth: {
        default: '40px',
        md: '36px'
      },
      width: {
        default: 'auto',
        md: 'auto'
      },
      minHeight: {
        default: '40px',
        md: '36px'
      },
      // background: Colors.gallery,
      backgroundOnHover: Colors.tango,
      color: Colors.msugreen,
      colorOnHover: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.4,
        md: 2.0,
        sm: 2.0
      },
      lineHeight: 1.2,
      fontWeight: 600
    },
    linkArrow: {
      borderRadius: { default: '4px', lg: '4px' },
      minWidth: {
        default: '40px',
        md: '36px'
      },
      width: {
        default: 'auto',
        md: 'auto'
      },
      minHeight: {
        default: '40px',
        md: '36px'
      },
      background: 'transparent',
      backgroundOnHover: 'transparent',
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50),
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      color: 'inherit',
      colorOnHover: 'inherit',
      faIconRight: faAngleDoubleRight,
      faIconLeft: faCaretLeft
    }
  },
  navMenu: {
    item: {
      padding: {
        lg: '20px 0',
        md: '10px 0'
      },
      border: Colors.blizzardBlue
    },
    icon: {
      border: `1px solid ${darken(Colors.mercury, 0.7)}`,
      borderRadius: '50%',
      boxShadow: `
        0 0 0 5px ${Colors.white},
        0 0 0 1px ${darken(Colors.mercury, 0.7)},
        0 3px 7px 4px ${rgba(Colors.black, 0.75)}`
    },
    title: {
      color: Colors.curiousBlue,
      colorOnHover: darken(Colors.curiousBlue, 50),
      fontSize: {
        default: 1.4,
        lg: 2.0,
        md: 1.8,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  menuList: {
    wrapper: {
      position: undefined,
      shape: undefined,
      padding: { default: 0, lg: 0 },
      borderRadius: { default: 0, lg: 0 },
      background: { default: 'transparent', lg: 'transparent' }
    },
    content: {
      position: undefined,
      margin: { default: '0' },
      justifyContent: 'flex-end'
    },
    link: {
      padding: {
        default: '5px 15px 15px',
        lg: '20px 0 10px',
        md: '10px 0 10px'
      },
      minHeight: {
        default: '100%',
        lg: 'auto'
      },
      border: Colors.blizzardBlue,
      color: Colors.msugreen,
      colorOnHover: Colors.tango,
      colorOnActive: Colors.tango,
      backgroundOnHover: 'transparent',
      backgroundOnActive: 'transparent',
      boxShadowOnHover: `none`,
      boxShadowOnActive: `none`,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.2,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 300,
      border: Colors.tango
    },
    subLink: {
      padding: {
        default: '5px 15px',
        lg: '20px 0',
        md: '10px 0'
      },
      minHeight: {
        default: '36px',
        lg: '36px'
      },
      border: Colors.blizzardBlue,
      color: Colors.msugreen,
      colorOnActive: Colors.tango,
      backgroundOnHover: Colors.blizzardBlue,
      backgroundOnActive: Colors.blizzardBlue,
      boxShadowOnHover: `none`,
      boxShadowOnActive: `none`,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 600
    },
    option: {
      padding: {
        default: '5px 15px 15px',
        lg: '20px 0 10px',
        md: '10px 0 10px'
      },
      minHeight: {
        default: '100%',
        lg: 'auto'
      },
      borderRadius: { default: 0, lg: 0 },
      border: Colors.tango,
      color: Colors.msugreen,
      colorOnActive: Colors.tango,
      backgroundOnHover: Colors.white,
      backgroundOnActive: 'transparent',
      boxShadowOnHover: `0 3px 6px 0 ${rgba(Colors.black, 0.16)}`,
      boxShadowOnActive: `none`,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.2,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 300
    },
    subOption: {
      padding: {
        default: '5px 15px',
        lg: '20px 0',
        md: '10px 0'
      },
      minHeight: {
        default: '36px',
        lg: '36px'
      },
      border: Colors.blizzardBlue,
      color: Colors.msugreen,
      colorOnHover: Colors.tango,
      colorOnActive: Colors.tango,
      backgroundOnHover: Colors.blizzardBlue,
      backgroundOnActive: Colors.blizzardBlue,
      backgroundMobileOnHover: 'transparent',
      backgroundMobileOnActive: 'transparent',
      boxShadowOnHover: `none`,
      boxShadowOnActive: `none`,
      boxShadowMobileOnHover: `none`,
      boxShadowMobileOnActive: `none`,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    itemContent: {
      background: { default: Colors.white, lg: 'transparent' },
      boxShadow: `0 6px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    arrow: {
      faIcon: faAngleDoubleRight,
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    },
    item: {
      border: Colors.tango,
      padding: {
        default: '0 0 15px',
        lg: '0 0 10px',
        md: '0 0 5px',
        sm: '0 0 2px'
      },
      evenBackground: Colors.cultured
    }
  },
  megamenu: {
    wrapper: {
      padding: {
        default: 0,
        lg: '20px 0 0',
        md: '10px 0'
      },
      background: { default: Colors.white, lg: 'transparent' },
      boxShadow: `2px 6px 6px 0 ${rgba(Colors.black, 0.4)}`,
      borderRadius: '4px'
    },
    main: {
      padding: {
        default: '20px 20px 0',
        lg: 0,
        md: 0
      }
    },
    aside: {
      border: rgba(Colors.black, 0.1),
      boxShadow: {
        default: `none`,
        lg: 'none'
      },
      maxWidth: '309px',
      padding: {
        default: '20px 40px 0',
        lg: 0,
        md: 0
      },
      background: {
        default: rgba(Colors.anakiwa, 0.3),
        lg: 'transparent'
      }
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.msugreen,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 2.4,
        lg: 2.2,
        md: 2.0,
        sm: 1.8
      },
      lineHeight: 1.2,
      fontWeight: 600
    },
    item: {
      padding: {
        default: '20px 0',
        md: '10px 0'
      },
      border: 'none'
    },
    image: {
      width: 50,
      height: 50,
      background: Colors.msugreen,
      borderRadius: {
        default: '0',
        md: '0'
      }
    },
    text: {
      color: Colors.msugreen,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      faIcon: faAngleDoubleRight,
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  megamenuList: {
    wrapper: {
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6),
      borderTop: `1px solid ${rgba(Colors.black, 0.1)}`
    },
    item: {
      padding: {
        default: '10px 0',
        lg: '10px 0',
        md: '5px 0'
      },
      borderBottom: `1px solid ${rgba(Colors.black, 0.1)}`
    },
    text: {
      color: Colors.msugreen,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.4,
        sm: 1.4
      },

      lineHeight: 1.2,
      fontWeight: 400
    },
    arrow: {
      faIcon: faAngleDoubleRight,
      color: Colors.tango,
      colorOnHover: darken(Colors.tango, 50)
    }
  },
  articlesGridNew: {
    wrapper: {
      margin: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      }
    },
    box: {
      border: 'transparent',
      padding: {
        default: '0 0 0 0',
        md: '0 0 0 0'
      },
      gridTemplateColumns: {
        default: '1fr 1fr 1fr 1fr',
        lg: '1fr 1fr',
        md: '1fr 1fr',
        sm: '1fr'
      },
      columnGap: {
        default: '25px',
        lg: '10px',
        md: '10px',
        sm: '10px'
      },
      rowGap: {
        default: '10px',
        lg: '10px',
        md: '10px',
        sm: '10px'
      }
    },
    itemHolder: {
      shape: undefined,
      main: {
        gridArea: {
          default: '1 / 1 / 2 / 2',
          lg: '1 / 1 / 2 / 2',
          md: '1 / 1 / 2 / 2',
          sm: '1 / 1 / 2 / 2'
        },
        gridAreaReverted: {
          default: '1 / 3 / 4 / 5',
          lg: '2 / 1 / 3 /3',
          md: '2 / 1 / 3 /3',
          sm: '2 / 1 / 2 /2'
        },
        margin: {
          default: '-35px 0 0 0',
          lg: '0 0 0  0',
          md: '0 0 0 0',
          sm: '0 0 0 0'
        },
        shape: ARTICLES_LIST_SHAPE,
        shapeBackground: Colors.brinkPink
      }
    },
    item: {
      color: `${Colors.white} !important`,
      backgroundColorOnHoverLink: darken(Colors.sprout, 25),
      backgroundColorOnHoverLinkMain: darken(Colors.white, 25),
      colorOnHoverLinkMain: Colors.white,
      gridColumns: {
        default: '1fr',
        lg: '1fr',
        md: '1fr',
        sm: '1fr'
      },
      height: '100%',
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        md: 0
      },
      background: 'transparent',
      borderRadius: {
        default: 0,
        md: 0
      },
      boxShadow: 'none',
      border: {
        left: 'none',
        right: 'none',
        bottom: 'none',
        top: 'none',
        lastOfType: {
          bottom: 'none'
        }
      },
      main: {
        gridColumns: {
          default: '1fr',
          lg: '1fr',
          md: '1fr',
          sm: '1fr'
        },
        margin: {
          default: '0 0 0 0',
          lg: 0,
          md: '0 0 0 0',
          sm: 0
        },
        padding: {
          default: '10px',
          lg: '25px 10px 10px 10px',
          md: '25px 10px 10px 10px',
          sm: '10px 10px 10px 10px'
        },
        background: Colors.sprout,
        borderRadius: {
          default: '0',
          md: '0'
        },
        boxShadow: `none`
      }
    },
    imagebox: {
      maxWidth: 'initial',
      order: '1',

      margin: {
        default: '0 0 10px 0',
        lg: '0 0 10px 0',
        md: '0 0 10px 0',
        sm: '0 0 10px 0'
      },
      main: {
        maxWidth: 'initial',
        margin: {
          default: '0',
          lg: '0',
          md: '0',
          sm: '0'
        }
      }
    },
    image: {
      width: 248,
      height: 186,
      widthTablet: 451,
      heightTablet: 338,
      widthMobile: 350,
      heightMobile: 262,

      borderRadius: {
        default: '0',
        md: '0'
      },
      main: {
        width: 528,
        height: 396,
        widthTablet: 451,
        heightTablet: 338,
        widthMobile: 730,
        heightMobile: 547,

        borderRadius: {
          default: '0',
          md: '0'
        }
      }
    },
    featured: {
      position: 'top-right-edge',
      shape: FEATURED_SHAPE,
      isText: false,
      minWidth: {
        default: '51px',
        lg: '51px',
        md: '41px',
        sm: '41px'
      },
      minHeight: {
        default: '44px',
        lg: '44px',
        md: '34px',
        sm: '34px'
      },
      borderRadius: {
        default: '100%',
        md: '100%'
      },
      background: Colors.grey,
      color: Colors.white,
      top: {
        default: '-15px',
        lg: '-15px',
        md: '-15px',
        sm: '-15px'
      },
      bottom: {
        default: 'unset',
        lg: 'unset',
        md: 'unset',
        sm: 'unset'
      },
      right: '-20px',
      fontSize: {
        default: 1.3,
        lg: 1.3,
        md: 1.3,
        sm: 1.3
      },
      lineHeight: 1.2,
      fontWeight: 700,
      main: {
        top: {
          default: '-15px',
          lg: '-15px',
          md: '-15px',
          sm: '-15px'
        },
        bottom: {
          default: 'unset',
          lg: 'unset',
          md: 'unset',
          sm: 'unset'
        },
        position: 'top-right-edge',
        shape: FEATURED_SHAPE,
        isText: false,
        minWidth: {
          default: '92px',
          lg: '82px',
          md: '72px',
          sm: '62px'
        },
        minHeight: {
          default: '72px',
          lg: '62px',
          md: '52px',
          sm: '42px'
        },
        borderRadius: {
          default: '100%',
          md: '100%'
        },
        background: 'transparent',
        color: Colors.white,
        fontSize: {
          default: 1.3,
          lg: 1.3,
          md: 1.2,
          sm: 1.2
        },
        lineHeight: 1.2,
        fontWeight: 700
      }
    },
    star: {
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.2,
        sm: 1.2
      },
      margin: '0 0 0 25%',
      color: Colors.white,
      faIcon: 'none',
      position: 'relative',
      main: {
        fontSize: {
          default: 2.5,
          lg: 2.5,
          md: 2,
          sm: 2
        },
        faIcon: 'none',
        margin: '0 0 0 25%',
        color: Colors.white
      }
    },
    details: {
      margin: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      padding: {
        default: 0,
        lg: 0,
        md: 0,
        sm: 0
      },
      main: {
        color: Colors.white,
        margin: {
          default: 0,
          lg: 0,
          md: 0,
          sm: 0
        },
        padding: {
          default: '15px 50px 0 15px',
          lg: '10px 50px 10px 10px',
          md: '15px',
          sm: '0 15px 15px'
        }
      }
    },
    title: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      wordBreak: 'break-word',
      color: Colors.black,
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      main: {
        color: Colors.white,
        fontSize: {
          default: 1.8,
          lg: 1.8,
          md: 1.6,
          sm: 1.6
        }
      },
      lineHeight: 1.2,
      fontWeight: 700
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: 'inherit',
      fontSize: {
        default: 1.4,
        lg: 1.4,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1.2,
      fontWeight: 400
    },
    link: {
      position: 'absolute',
      padding: '10px 30px 10px 25px',
      backgroundColor: Colors.sprout,
      transform: 'rotate(-90deg) translateX(86px)',
      isText: false,
      color: Colors.white,
      colorOnHover: lighten(Colors.black, 50),
      fontFamily: 'Metropolis, sans-serif',
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 700,
      main: {
        isText: true,
        fontSize: {},
        color: Colors.sprout,
        backgroundColor: Colors.white,
        transform: 'rotate(-90deg) translateX(8px)',
        transformLg: 'rotate(-90deg) translateX(-180px)'
      }
    },
    arrow: {
      minWidth: '0',
      minHeight: '0',
      borderRadius: {
        default: '100%',
        md: '100%'
      },
      invert: true,
      color: Colors.brinkPink,
      colorOnHover: darken(Colors.brinkPink, 50),
      background: Colors.white,
      backgroundOnHover: Colors.white,
      faIcon: 'none',
      main: {
        fontSize: {}
      }
    }
  },
  galleriesGrid: {
    box: {
      columnGap: '25px',
      rowGap: '17px'
    },
    imagebox: {
      margin: {
        default: '0 0 10px 0'
      },
      after: {
        padding: '8px 23px 8px 23px',
        backgroundColor: Colors.sprout,
        backgroundColorOnHover: darken(Colors.sprout, 25),
        color: Colors.white,
        content: 'więcej',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: 1.4,
        fontWeight: 700,
        lineHeight: 1.2
      }
    },
    text: {
      color: Colors.black,
      fontSize: {
        default: 1.5,
        lg: 1.5,
        md: 1.5,
        sm: 1.5
      },
      fontWeight: 700,
      lineHeight: 1.2
    }
  },
  cookies: {
    wrapper: {
      background: Colors.white,
      boxShadow: `0 -3px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    text: {
      margin: {
        default: '15px 0',
        md: '5px 0'
      },
      color: Colors.msugreen,
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  CMS: {
    color: Colors.msugreen,
    fontSize: {
      default: 1.6,
      lg: 1.6,
      md: 1.4,
      sm: 1.4
    },
    lineHeight: 1.2,
    fontWeight: 400,
    cms: {
      h1: {
        fontFamily: 'Lato, sans-serif',
        fontSize: {
          default: 2.4,
          lg: 2.2,
          md: 2.0,
          sm: 1.8
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h2: {
        fontSize: {
          default: 2.4,
          lg: 2.2,
          md: 2.0,
          sm: 1.8
        },
        lineHeight: 1.2,
        fontWeight: 900
      },
      h3: {
        fontSize: {
          default: 2.2,
          lg: 2.0,
          md: 1.8,
          sm: 1.8
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h4: {
        fontSize: {
          default: 2.0,
          lg: 1.8,
          md: 1.8,
          sm: 1.6
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h5: {
        fontSize: {
          default: 1.8,
          lg: 1.6,
          md: 1.6,
          sm: 1.4
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      h6: {
        fontSize: {
          default: 1.6,
          lg: 1.6,
          md: 1.4,
          sm: 1.4
        },
        lineHeight: 1.2,
        fontWeight: 400
      },
      a: {
        color: Colors.msugreen,
        colorOnHover: darken(Colors.msugreen, 50)
      },
      mark: {
        background: Colors.candlelight
      },
      ul: {
        padding: {
          default: '0 0 0 25px',
          md: '0 0 0 15px'
        }
      },
      ol: {
        padding: {
          default: '0 0 0 25px',
          md: '0 0 0 15px'
        }
      },
      table: {
        border: Colors.msugreen
      },
      tr: {
        background: Colors.white,
        backgroundAlt: Colors.mercury
      },
      th: {
        border: Colors.msugreen
      },
      td: {
        border: Colors.msugreen
      },
      blockquote: {
        fontFamily: 'Lato, sans-serif',
        fontSize: {
          default: 1.8,
          lg: 1.8,
          md: 1.6,
          sm: 1.6
        },
        lineHeight: 1.2,
        fontWeight: 400,
        quote: {
          margin: {
            default: '-7.0rem 20px 0 0',
            md: '-5.0rem 20px 0 0',
            sm: '-4.0rem 20px 0 0'
          },
          color: Colors.tango,
          background: 'transparent',
          fontFamily: 'Lato, sans-serif',
          fontSize: {
            default: 18.0,
            lg: 18.0,
            md: 14.0,
            sm: 10.0
          },
          lineHeight: 1,
          fontWeight: 400
        }
      }
    }
  },
  modal: {
    holder: {
      background: rgba(Colors.black, 0.6),
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    },
    panel: {
      background: Colors.white,
      shadow: `0 3px 6px 0 ${rgba(Colors.black, 0.16)}`
    },
    close: {
      color: Colors.msugreen,
      colorOnHover: Colors.tango
    }
  },
  popup: {
    wrapper: {
      thumb: Colors.tango,
      track: rgba(Colors.black, 0.6)
    }
  },
  footerMenu: {
    wrapper: {
      background: Colors.darkCyan,
      padding: {
        default: '2px 0',
        md: '2px 0'
      }
    },
    item: {
      color: Colors.white,
      colorOnHover: darken(Colors.white, 50),
      colorOnActive: darken(Colors.white, 50),
      background: 'transparent',
      backgroundOnHover: 'transparent',
      backgroundOnActive: 'transparent'
    },
    text: {
      fontSize: {
        default: 1.8,
        lg: 1.8,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400
    }
  },
  footerDetails: {
    wrapper: {
      background: Colors.msugreen,
      padding: {
        default: '45px 0 30px',
        md: '25px 0 15px'
      }
    },
    column: {
      border: `2px solid ${rgba(Colors.black, 0.3)}`,
      paddingInline: '48px'
    },
    item: {
      margin: {
        default: '0 0 25px 0',
        md: '0 0 15px 0'
      }
    },
    text: {
      margin: {
        default: '0 0 15px 0',
        md: '0 0 5px 0'
      },
      color: Colors.white,
      colorOnHover: Colors.white,
      colorOnActive: Colors.white,
      fontSize: {
        default: 1.6,
        lg: 1.6,
        md: 1.6,
        sm: 1.6
      },
      lineHeight: 1.2,
      fontWeight: 400,
      link: {
        color: 'inherit',
        colorOnHover: 'inherit'
      }
    }
  },
  footerCopyright: {
    wrapper: {
      background: Colors.darkBlue,
      padding: {
        default: '12px 0',
        md: '6px 0'
      }
    },
    text: {
      color: Colors.white,
      fontFamily: 'Lato, sans-serif',
      fontSize: {
        default: 1.5,
        lg: 1.5,
        md: 1.4,
        sm: 1.4
      },
      lineHeight: 1,
      fontWeight: 400,
      textAlign: {
        default: 'left',
        sm: 'center'
      },
      link: {
        color: 'inherit',
        colorOnHover: 'inherit'
      }
    }
  }
};

export default theme;
