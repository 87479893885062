export const Breakpoints = {
  sm: 767,
  md: 969,
  lg: 1024,
  xlg: 1200,
  xxlg: 1280,
  container: 1425,
  banner: 1604
};

export const Colors = {
  white: '#ffffff',
  mercury: '#e3e3e3',
  black: '#000000',
  bigStone: '#192845',
  tango: '#F26A1B',
  curiousBlue: '#3299D9',
  blizzardBlue: '#BDD9F2',
  candlelight: '#F9D71C',
  waikawaGray: '#5864A6',
  astronautBlue: '#033B62',
  brinkPink: '#F96A9A',
  solitude: '#E6F5FF',
  tiber: '#092B43',
  prussianBlue: '#002244',
  blackPearl: '#040F1A',
  gallery: '#EFEFEF',
  galleryTwo: '#EEEEEE',
  anakiwa: '#96E8FE',
  pictonBlue: '#28C2F3',
  alabaster: '#FBFBFB',
  sprout: '#B1D29D',
  camouflageGreen: '#738866',
  darkRed: '#c00518',
  shiraz: '#a40c1e',
  lightBlack: '#1e1e1c',
  grey: '#e8e8e8',
  darkGrey: '#717171',
  mercury2: '#e7e7e7',
  doveGray: '#707070',
  gray68: '#adadad',
  bluewood: '#364b5c',
  blueberry: '#475d6e',
  pickled: '#314757',
  newYork: '#db7f7e',
  card: '#fdfcf7',
  cardOpacity: '#fdfcf7b3',
  wolfGrey: '#BECAD4',
  orange: '#FF9A34',
  casper: '#AAC9D4',
  lightGreen: '#88C4A6',
  ziggurat: '#BED6DF',
  jetStream: '#ADD3C8',
  acapulco: '#7CB498',
  darkCyan: '#0ca19b',
  darkBlue: '#0f2634',
  crayola: '#2f373a',
  msugreen: '#0f3c5d',
  cultured: '#f5f5f5'
};
