import React from 'react';
import { useTheme } from 'ThemeContext';
import { ReactSVG } from 'react-svg';

import {
  Holder,
  Wrapper,
  Box,
  ItemHolder,
  Item,
  ItemExternal,
  Imagebox,
  Image,
  Title,
  TitleContent,
  Arrow
} from './BannerTravelRounded.style';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IMAGE_PLACEHOLDER from 'assets/Images/placeholder-50x50.png';

function BannerTravelRounded({ data = [] }) {
  const theme = useTheme();
  console.log('bannerTravel', theme);
  return (
    <Holder theme={theme.bannerTravelRounded.holder}>
      {theme.bannerTravelRounded.holder.shape && (
        <ReactSVG
          className='shape'
          src={theme.bannerTravelRounded.holder.shape}
        />
      )}
      <Wrapper theme={theme.bannerTravelRounded.wrapper}>
        <Box theme={theme.bannerTravelRounded.box}>
          {data &&
            data
              .filter(item => item.widget_id === 6 && item.data)
              .map(item => ({ ...item.data }))
              .map((item, key) => {
                const regExp = new RegExp('(.*)(.jpg|.jpeg|.png)$');
                let currentImage = item.thumbnail || item.image;
                let currentImageMatch;
                if (currentImage) {
                  currentImageMatch = currentImage.match(regExp);
                  if (
                    currentImageMatch &&
                    currentImageMatch[1] &&
                    currentImageMatch[2]
                  )
                    currentImage = `${currentImageMatch[1]}_${theme.bannerTravelRounded.image.width}x${theme.bannerTravelRounded.image.height}${currentImageMatch[2]}`;
                }

                return (
                  <ItemHolder
                    key={key}
                    theme={theme.bannerTravelRounded.itemHolder}
                    lastItem={key === data.length - 1}
                  >
                    {(item.slug && /(http(s?)):\/\//gi.test(item.slug)) ||
                    (item.url && /(http(s?)):\/\//gi.test(item.url)) ||
                    item.url_outside ? (
                      <ItemExternal
                        theme={theme.bannerTravelRounded.item}
                        title={item.title}
                        href={item.slug || item.url || item.url_outside}
                        target={
                          item._blank ||
                          item.url_blank ||
                          item.url_outside_blank
                            ? '_blank'
                            : '' || item.target || ''
                        }
                        rel={
                          item.nofollow ||
                          item.url_nofollow ||
                          item.url_outside_nofollow
                            ? 'nofollow'
                            : '' || item.rel || ''
                        }
                      >
                        <Title theme={theme.bannerTravelRounded.title}>
                          {theme.bannerTravelRounded.titleContent ? (
                            <TitleContent
                              theme={theme.bannerTravelRounded.titleContent}
                            >
                              {item.title}
                            </TitleContent>
                          ) : (
                            item.title
                          )}

                          <Arrow theme={theme.bannerTravelRounded.arrow}>
                            <FontAwesomeIcon
                              icon={theme.bannerTravelRounded.arrow.faIcon}
                            />
                          </Arrow>
                        </Title>
                      </ItemExternal>
                    ) : (
                      <Item
                        theme={theme.bannerTravelRounded.item}
                        title={item.title}
                        smooth
                        to={
                          item.slug &&
                          (item.slug[0] === '/' || item.slug[0] === '#')
                            ? item.slug
                            : `/${item.slug}`
                        }
                      >
                        <Title theme={theme.bannerTravelRounded.title}>
                          {theme.bannerTravelRounded.titleContent ? (
                            <TitleContent
                              theme={theme.bannerTravelRounded.titleContent}
                            >
                              {item.title}
                            </TitleContent>
                          ) : (
                            item.title
                          )}
                          <Arrow theme={theme.bannerTravelRounded.arrow}>
                            <FontAwesomeIcon
                              icon={theme.bannerTravelRounded.arrow.faIcon}
                            />
                          </Arrow>
                        </Title>
                      </Item>
                    )}
                  </ItemHolder>
                );
              })}
        </Box>
      </Wrapper>
    </Holder>
  );
}

export default BannerTravelRounded;
