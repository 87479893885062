import styled, { css } from 'styled-components';
import { HashLink as RouterLink } from 'react-router-hash-link';

import { Media } from 'assets/Mixins.style';

export const Holder = styled.div`
  position: relative;

  ${props =>
    props.theme.shape &&
    css`
      overflow: hidden;
      padding-bottom: 40px;

      ${Media.lg`
        overflow: inherit;
        padding-bottom: inherit;
        `}

      .shape {
        position: absolute;
        display: inline-flex;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        ${Media.lg`
          display: none;
        `}

        div {
          width: 100%;
          height: 100%;
        }

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: ${props => props.theme.shapeBackground};
          }
        }
      }
    `};
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 100%;
  min-height: 90px;
  margin: ${props => props.theme.margin.default};
  border-radius: ${props => props.theme.borderRadius.default};
  background: ${props => props.theme.background};
  box-shadow: ${props => props.theme.boxShadow.default};

  ${Media.lg`
    margin: ${props => props.theme.margin.lg};
    border-radius: ${props => props.theme.borderRadius.lg};
    box-shadow: ${props => props.theme.boxShadow.lg};
    padding: 10px 0;
  `}
`;

export const ItemHolder = styled.div`
  display: flex;
  justify-content: ${props =>
    props.theme.justifyContent && props.theme.justifyContent.default
      ? props.theme.justifyContent.default
      : 'center'};
  flex-grow: ${props => props.theme.flexGrow || 1};
  padding: ${props => props.theme.padding};
  border-right: ${props => !props.lastItem && props.theme.borderRight};
  width: auto;    
  ${props =>
    props.theme.theme5 &&
    css`
      border-right: 0;
    `}
  
  ${Media.lg`
      border-right: none;
      border-bottom: ${props => props.theme.borderRight};
  `}

  ${Media.lg`
      justify-content: ${props =>
        props.theme.justifyContent && props.theme.justifyContent.default
          ? props.theme.justifyContent.default
          : 'flex-start'};
  `}

  ${Media.lg`
    border-bottom: 0;
    width: 50%;
  `}
  ${Media.sm`
    width: auto;
  `}
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: ${props => props.theme.padding.default};
  ${props => (props.theme.gap ? `gap: ${props.theme.gap}` : '')};

  ${Media.lg`
    flex-direction: column;
    padding: ${props => props.theme.padding.lg};
  `}

  ${Media.lg`
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px 0;
  `}

  ${Media.sm`
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
  `}
`;

const ItemStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  padding: ${props => props.theme.padding.default};
  border-right: 1px solid ${props => props.theme.border};
  transition: 0.2s all;
  cursor: pointer;

  ${Media.lg`
    display: grid;
    padding: ${props => props.theme.padding.lg};
    border-right:0;
    border-bottom: 1px solid ${props => props.theme.border};
  `}

  ${Media.md`
    padding: ${props => props.theme.padding.md};
  `}

  &:last-of-type {
    border-right: 0;
    ${Media.lg`
      border-bottom: 0;
    `}
  }
  border-right: 0;
  background: ${props => props.theme.roundedButton.background};
  border-radius: 50%;
  width: 220px;
  height: 220px;
  &:hover {
    background: ${props => props.theme.roundedButton.backgroundHover};
  }
`;

export const Item = styled(RouterLink)`
  ${ItemStyle}
`;

export const ItemExternal = styled.a`
  ${ItemStyle}
`;

export const Title = styled.h2`
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5lh 1fr;
  gap:10px;
  width:150px;
  height: 150px;
  align-items: center;
  transition: 0.2s all;
  color: ${props => props.theme.color};
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => `${props.theme.fontSize.default}rem`};
  line-height: ${props => props.theme.lineHeight};
  font-weight: ${props => props.theme.fontWeight};
  margin-left: ${props => props.theme.bannerTitleSpacing};
  font-weight: 300;
  ${Media.lg`
    font-size: ${props => `${props.theme.fontSize.lg}rem`};
  `}

  ${Media.md`
    font-size: ${props => `${props.theme.fontSize.md}rem`};
  `}

  ${Media.sm`
    font-size: ${props => `${props.theme.fontSize.sm}rem`};
  `}


`;

export const TitleContent = styled.span`
  display: block;
  height: 100%;

  overflow: hidden;
  text-align: center;
`;

export const Arrow = styled.i`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 5px;
  color: ${props => props.theme.color};
  vertical-align: middle;
  transition: 0.2s all;

  ${Item}:hover &,
  ${ItemExternal}:hover & {
    color: ${props => props.theme.colorOnHover};
  }
`;
